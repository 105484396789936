.tenders-page {
    padding: 2rem;
    background-color: #f8f9fa;
}

.tenders-intro h1 {
    font-size: 1.5rem;
    color: #333;
    margin-top: 5rem;
    margin-bottom: 1rem;
 

}


.tenders-buttons {
    margin: 1.5rem 0;
}
.address{
    font-size: 1.3rem;
    color: #333;
    margin-bottom: 1rem;

}
.apply-button, .tier-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.apply-button:hover, .tier-button:hover {
    background-color: #0056b3;
}

.tenders-table {
    margin-top: 2rem;
}

.tenders-table h2 {
    font-size: 1.25rem;
    margin-bottom: 1rem;

}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
}

table, th, td {
    border: 1px solid #ddd;
}

th, td {
    padding: 0.75rem;
    text-align: left;
    font-size: 0.9rem;
}

th {
    background-color: #f2f2f2;
    color: #333;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}
