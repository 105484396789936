/* Container for the Our Partners page */

  /* Introduction Section */
  .our-partners-page{
    min-height: 70vh;
  }
  .introduction {
    text-align: center;
    margin-bottom: 3rem;
  }
  .our-partners-page h1{
    font-size: 2.5rem;
    margin-bottom: 1rem;
    background: linear-gradient(to right, #E4C664 0%, #B18325 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  /* Partners Section */
  .partners {
    text-align: center;
  }
  
  /* Partners list */
  .partners-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 2px;
  }
  
  .partner-item {
    max-width: 130px; /* Adjust the size as needed */
    text-align: center;
  }
  
  .partner-item img {
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .partner-item p {
    margin-top: 0.5rem;
  }
  
  /* Hover effect for partner items */
  .partner-item:hover img {
    transform: scale(1.1); /* Slight zoom effect on hover */
  }
  