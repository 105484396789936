/* General styles for the About page */
.about-page{
  padding: 10px;
  width: 70%;
  margin: auto;
}


.about-page__header {
  margin-top: 130px !important;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 30px; /* Reduced margin */
  background: linear-gradient(to right, #E4C664 0%, #B18325 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* About sections layout */
.about-page__sections {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

/* About section styling */
.about-section {
  display: flex;
  align-items: center;
  gap: 20px;
}

.about-section__image img {
  width: 100%;
  width: 450px; /* Fixed width */
  min-height: 250px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.about-section__description {
  max-width: 80%; /* Adjusted for larger screens */
}

.about-section__description h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #007bff;
}

.about-section__description p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
}

/* Layout for alternating image and text */
.about-section--left {
  flex-direction: row;
}

.about-section--right {
  flex-direction: row-reverse;
}

/* Animation */
.motion-element {
  transition: all 0.6s ease;
}

/* Responsive design */
@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
    text-align: center;
  }

  .about-section__image img {
    width: 100%;
  }

  .about-section__description {
    max-width: 100%;
  }
}
