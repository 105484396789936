/* General styles for the service page */
.services-page {
    padding: 50px;
    background-color: #f9f9f9;
  }
  
  .services-header {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  /* Grid layout for service cards */
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .service-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .service-icon {
    font-size: 3rem;
    color: #007bff; /* Primary color for the icons */
    margin-bottom: 15px;
  }
  
  .service-card h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .service-card p {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .services-header {
      font-size: 2rem;
    }
  
    .service-card {
      padding: 20px;
    }
  
    .service-icon {
      font-size: 2.5rem;
    }
  
    .service-card h3 {
      font-size: 1.3rem;
    }
  
    .service-card p {
      font-size: 0.9rem;
    }
  }
  