/* General Page Styles */
.home-page {
  position: relative;
  width: 100%;
  height: auto;
}

/* Carousel Slide Styles */
.carousel-slide {
  height: 100vh; /* Full screen height */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.carousel-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.text-container {
  position: absolute;
  bottom: 15%; /* Move text container towards the bottom */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
  color: #fff;
}

.text-container h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.text-container p {
  font-size: 1.5rem;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

/* Small Screen Text */
.small-screen-text {
  padding: 2rem;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}

/* Responsive adjustments for medium and small screens */
@media screen and (max-width: 992px) {
  /* Medium screens (tablets, etc.) */
  .carousel-slide {
    height: 70vh; /* Reduce height */
  }

  .text-container h1 {
    font-size: 2.5rem; /* Reduce font size */
  }

  .text-container p {
    font-size: 1.3rem;
  }

  .text-container {
    bottom: 10%; /* Adjust text position near the bottom */
  }
}

@media screen and (max-width: 768px) {
  /* Small screens (phones, small tablets, etc.) */
  .carousel-slide {
    height: 50vh; /* Further reduce height */
  }

  .text-container h1 {
    font-size: 2rem;
  }

  .text-container p {
    font-size: 1.1rem;
  }

  .text-container {
    bottom: 8%; /* Adjust text position near the bottom */
  }
}

@media screen and (max-width: 480px) {
  /* Extra small screens (phones) */
  .carousel-slide {
    height: 40vh; /* Further reduce height */
  }

  .text-container h1 {
    font-size: 1.8rem;
  }

  .text-container p {
    font-size: 1rem;
  }

  .text-container {
    bottom: 5%; /* Move text closer to the bottom */
  }
}
