/* Navbar.css */

/* Navbar styles */
.navbar {
  background-color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  box-sizing: border-box; /* Ensure padding/border included in height */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin: 0 auto;
  box-sizing: border-box; /* Ensure padding/border included in width */
}

.navbar-placeholder {
  height: 70px; /* Match this to your navbar height */
}

  
  .navbar-logo img {
    height: 60px; /* Increased logo size */
  }
  
  .navbar-links {
    display: flex;
    gap: 20px;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    font-weight: 500;
  }
  
  .navbar-links a:hover {
    color: #007bff;
  }
  
  .navbar-mobile-menu {
    display: none;
  }
  
  .menu-icon {
    width: 24px;
    height: 24px;
    color: white;
  }
  
  .navbar-mobile-menu button {
    background-color: black;
    border: none;
    padding: 10px;
    border-radius: 4px;
  }
  
  .navbar-mobile-menu button:focus {
    outline: none;
  }
  
  /* Dropdown Menu on Small Screens */
  .navbar-links.open {
    display: block;
    position: absolute;
    /* text-align: right; */
    right: 0;
    top: 60px;
    background-color: black;
    width: 300px; /* Adjusted width for smaller screens */
    text-align: left;
    padding: 10px 0;
  }
  
  .navbar-links.open a {
    display: block;
    padding: 10px 20px;
    text-decoration: none; /* Remove underline */
  }
  
  .navbar-links.open a:hover {
    color: #007bff;
    text-decoration: none; /* Ensure no underline on hover */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .navbar-links {
      display: none;
    }
  
    .navbar-mobile-menu {
      display: block;
    }
  }
  