.contact-wrapper {
  color: #333;
  margin: auto;
  width: 70%;
}

.contact-title {
  text-align: center;
  font-size: 2.5rem;
  margin-top: 10%;
  margin-bottom: 1rem;
}

.contact-description {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 3rem;
  max-width: 800px;
  margin: 0 auto;
}

.contact-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

.contact-details, .contact-form-wrapper {
  min-width: 300px;
  padding: 1.5rem;
}

.contact-details-title, .form-header {
  font-size: 1.8rem;
  margin-bottom: 1rem;

}

.contact-section-wrapper {
  margin-bottom: 1.5rem;
}

.contact-heading {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.contact-paragraph, .form-label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.form-group-wrapper {
  margin-bottom: 1.5rem;
}

.form-input,
.form-textarea {
  width: 90%; /* Ensures the input stretches to the container's width */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  padding: 10px; /* Padding for better readability */
  border: 1px solid #ccc; /* Light border for better visual separation */
  border-radius: 4px; /* Rounded corners */
  font-size: 16px; /* Consistent font size */
}


.submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-body {
    flex-direction: column;
    align-items: center;
  }

  .contact-details, .contact-form-wrapper {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .contact-wrapper {
    width: 90%;
    margin: auto;
    margin-top: 25%;
  }
  
  .contact-title {
    font-size: 2rem;
    margin-top: 20% !important;
  }

  .contact-description {
    font-size: 1rem;
  }

  .contact-details-title, .form-header {
    font-size: 1.5rem;
  }

  .contact-heading {
    font-size: 1rem;
  }
}
